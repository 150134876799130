import ApiService from "../../core/services/api.service";

const apiService = ApiService;

export default class DashboardService {
  #api = null;

  constructor() {
    this.#api = `admin`;
  }

  all() {
    let url = `${this.#api}/get/all/stats`;
    return apiService.get(url);
  }
}
