<template>
  <v-app>
    <!--begin::Dashboard-->
    <div class="row m-2">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header border-0">
            <v-row class="text-center">
              <v-col
                class="text-center"
                cols="6"
                md="3"
                sm="4"
                v-if="checkIsAccessible('user', 'list')"
              >
                <router-link :to="{ name: 'users' }">
                  <div
                    class="col-md-12 dashboard-quick-icons border border-gray m-1"
                  >
                    <img
                      :src="'media/dashboard/user.png'"
                      style="height: 64px"
                      alt="user"
                    />
                    <p>Users</p>
                    <v-divider></v-divider>
                    <strong>
                      {{ user_count }}
                    </strong>
                  </div>
                </router-link>
              </v-col>

              <v-col
                class="text-center"
                cols="6"
                md="3"
                sm="4"
                v-if="checkIsAccessible('admin-user', 'list')"
              >
                <router-link :to="{ name: 'admin' }">
                  <div
                    class="col-md-12 dashboard-quick-icons border border-gray m-1"
                  >
                    <img
                      :src="'media/dashboard/profile.png'"
                      style="height: 64px"
                      alt="admin user"
                    />
                    <p>Admin Users</p>
                    <v-divider></v-divider>
                    <strong>
                      {{ admin_user_count }}
                    </strong>
                  </div>
                </router-link>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <!--end::Dashboard-->
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DashboardService from "@/service/dashboard/DashboardService";

const dashboardService = new DashboardService();
export default {
  name: "dashboard",
  data() {
    return {
      admin_user_count: 0,
      user_count: 0
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.getAllStat();
  },
  methods: {
    getAllStat() {
      dashboardService.all().then(response => {
        this.admin_user_count = response.data.admin_user_count;
        this.user_count = response.data.user_count;
      });
    }
  }
};
</script>
